.iconDelete {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 20px;
  background: white;
  color: rgb(100, 100, 100);
  z-index: 1;
  border-radius: 100%;
}

.modal-content {
  border: none !important;
  border-radius: 10px !important;
}

.modal-footer {
  padding: 0 5px 5px 5px !important;
  border-top: none !important;
}
