main {
  padding-top: 5px;
  background: linear-gradient(#dfe0fb, #FFFFFF 50%);
}

.logoText {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

h1 {
  font-size: 2em !important;
  margin-bottom: 0 !important;
  font-family: 'Roboto';
  font-weight: bold;
}

.slogan {
  margin-top: 50px;
  color: #636EFA;
  font-size: 40px;
  line-height: 45px;
  font-family: 'Roboto';
  font-weight: bold;
}

.subSlogan {
  margin-top: 30px;
  font-weight: bold;
  margin-left: 5%;
}

.buttons {
  margin-left: 5%;
}

.downloadButton {
  margin-top: 15px;
  height: 50px;
  margin-right: 10px;
}

.explication {
  margin-top: 40px;
}

.how {
  font-size: 1.2em;
  color: #636EFA;
  font-family: 'Roboto';
  font-weight: bold;
}

.colVideo {
  text-align: center;
}

video {
  width: 90%;
  border-radius: 10px;
  max-height: 500px;
}

.footer {
  padding: 20px 0;
  color: #636EFA;
  font-weight: bold;
  
}

.footerTexts {
  text-align: center;
}

a {
  color: #636EFA !important;
  /* text-decoration: none !important; */
}

hr {
  color: #636EFA;
}
